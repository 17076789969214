import { FC, useEffect, useRef, useState } from 'react'
import jsLogger from 'js-logger'

import { getRecapInfo } from 'controllers/main'
import DataContent from 'pages/recap/DataContent'
import PageContainer from 'shared/components/PageContainer'
import Loading from 'components/Loading'
import { TwoFactorT } from 'types/twoFactor'
import { checkIsWebMSupported } from 'controllers/main'
import PACKAGE from '../../package.json'
import { getBackClient } from 'controllers/back'
import { ReactComponent as Icon } from 'assets/facesign-ai-icon.svg'
import AnalysisContent from 'pages/recap/AnalysisContent'

declare global {
  interface Window {
    fireTestEvent?: (event: string, data: any) => void
  }
}

type Props = {
  interactionId: string
  chunks: { user: Blob[]; avatar: Blob[] }
  recapData: TwoFactorT.RecapT | null
  isTest: boolean
}

const Recap: FC<Props> = ({ interactionId, chunks, recapData, isTest }) => {
  const [data, setData] = useState<TwoFactorT.RecapT | null>(recapData)
  const [videoOpen, setVideoOpen] = useState<boolean>(false)
  const updateTimerRef = useRef<number>()
  const requestsAmountRef = useRef(0)
  const isWebmSupported = useRef(false)

  useEffect(() => {
    if (isTest && data && data?.aiAnalysis) {
      if (window.fireTestEvent) {
        window.fireTestEvent('ev_ai_analysis_ready', {
          interactionId,
          version: PACKAGE.version
        })
      }
    }
  }, [isTest, data?.aiAnalysis, data, interactionId])

  useEffect(() => {
    isWebmSupported.current = checkIsWebMSupported()
  }, [interactionId, data])

  useEffect(() => {
    jsLogger.log('RECAP page', { interactionId })
    const back = getBackClient()
    back.disconnect()
    const fetchData = async () => {
      const res = await getRecapInfo(interactionId)
      requestsAmountRef.current = requestsAmountRef.current + 1
      if (requestsAmountRef.current > 10 || (res && res.aiAnalysis)) {
        clearInterval(updateTimerRef.current)
      }
      setData(res)
    }

    updateTimerRef.current = window.setInterval(fetchData, 5000)
    fetchData()

    return () => clearInterval(updateTimerRef.current)
  }, [interactionId, chunks])

  const aiAnalysis = data?.aiAnalysis || data?.aiAnalysisFirst

  const renderInfo = () => {
    if (data) {
      return (
        <div className='flex flex-col flex-1 overflow-y-scroll no-scrollbar'>
          <div className='flex flex-col pt-14 px-4 pb-6 portrait:bg-gray-50'>
            <div className='flex flex-row items-center justify-between mb-4'>
              <div className='flex flex-row items-center justify-start gap-2'>
                <Icon />
                <p className='font-medium text-xl text-gray-800'>
                  {videoOpen ? 'Session Video Playback' : 'Session summary'}
                </p>
              </div>
            </div>
            {data && <DataContent recap={data} />}
          </div>
          <div className='px-4'>
            <AnalysisContent
              overallSummary={aiAnalysis?.overallSummary}
              analysis={aiAnalysis?.analysis}
              steps={data.steps}
            />
          </div>
        </div>
      )
    }
  }

  if (!data) {
    return (
      <PageContainer version={PACKAGE.version}>
        <Loading waitingForUpload={false} />
      </PageContainer>
    )
  } else {
    return (
      <PageContainer version={PACKAGE.version}>{renderInfo()}</PageContainer>
    )
  }
}

export default Recap
