const CHUNK_SIZE = 500

const MIME_TYPES = [
  'video/webm;codecs="vp8,opus"',
  'video/webm;codecs=vp9',
  'video/webm;codecs=h264',
  'video/webm',
  'video/mp4'
]

class RVideo {
  private _blobs: Blob[] = []
  private _recorder: MediaRecorder
  private _onStop: (duration: number) => void
  private _onNewChunkReady: (v: Blob) => void
  private startedAt: number = Date.now()
  private endedAt: number = Date.now()
  private _mimeType?: string = 'video/webm'

  constructor (
    stream: MediaStream,
    onStop: (duration: number) => void,
    onNewChunkReady: (blob: Blob) => void
  ) {
    this._onStop = onStop
    this._onNewChunkReady = onNewChunkReady
    const mimeType = window.MediaRecorder.isTypeSupported
      ? MIME_TYPES.find(window.MediaRecorder.isTypeSupported)
      : 'video/webm'

    console.log('NEW RVideo, mimeType', mimeType)
    this._mimeType = mimeType
    // const videoTrack = stream.getVideoTracks()[0]
    // const { width, height } = videoTrack.getSettings()
    // const maxWidth = 480
    // const maxHeight = 480

    // if (width && height) {
    //   const aspectRatio = width / height
    //   let newWidth = Math.min(width, maxWidth)
    //   let newHeight = Math.round(newWidth / aspectRatio)

    //   if (newHeight > maxHeight) {
    //     newHeight = maxHeight
    //     newWidth = Math.round(newHeight * aspectRatio)
    //   }

    //   videoTrack.applyConstraints({ width: newWidth, height: newHeight })
    // }

    this._recorder = new window.MediaRecorder(stream, {
      mimeType,
      videoBitsPerSecond: 100000,
      audioBitsPerSecond: 44000
    })
    this._recorder.addEventListener('stop', this._handleStop)
    this._recorder.addEventListener('error', this._handleError)
    this._recorder.addEventListener('dataavailable', this._handleDataAvailable)
    console.log('VRecorder Start')
    this._recorder.start(CHUNK_SIZE)
    this.startedAt = Date.now()
  }

  private _handleDataAvailable = (event: BlobEvent) => {
    this._blobs.push(event.data)
    this._onNewChunkReady(event.data)
  }

  get blobs () {
    return this._blobs
  }

  get mimeType () {
    return this._mimeType
  }

  get duration () {
    return this.endedAt - this.startedAt
  }

  stop = () => {
    console.log('VRecorder Stop')
    this._recorder.stop()
  }

  cancel = () => {
    console.log('VRecorder Cancel')
    this._recorder.removeEventListener('stop', this._handleStop)
    this._recorder.removeEventListener('error', this._handleError)
    this._recorder.removeEventListener(
      'dataavailable',
      this._handleDataAvailable
    )
    this._blobs = []
    this._recorder.stop()
  }

  private _handleStop = () => {
    this.endedAt = Date.now()
    this._onStop(this.duration)
  }

  private _handleError = (e: any) => {
    console.error('Rvideo error:', e)
  }
}

export default RVideo
