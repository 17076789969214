import { FC, useRef, useState, useEffect, useMemo } from 'react'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import { MediaPermissionsErrorType, requestMediaPermissions } from 'mic-check'
import { DotLottieReact } from '@lottiefiles/dotlottie-react'

import {
  applyResponse,
  applyTestScreenshots,
  sendAvatarLatency,
  sendUserScreenshot,
  submitReply2
} from 'controllers/main'
import HeyGenConnect, {
  IHeyGenConnect
} from 'shared/components/HeyGenConnectSdk'
import AzureConnect from 'shared/components/AzureConnect'
import CustomAvatarConnect from 'shared/components/CustomAvatarConnect'
import Recorder, { IRecorder } from 'shared/components/Recorder'
import RecorderDummy from 'shared/components/RecorderDummy'
import PageContainer from 'shared/components/PageContainer'
import { ControlT } from 'types/internal'
import { PermissionErrorType } from 'types/proto'
import { TwoFactorT } from 'types/twoFactor'
import EndConversation from 'shared/components/EndConversation'
import ControlPanel, {
  IControlPanel,
  Step
} from 'shared/components/ControlPanel'
import { captureThumb } from 'shared/components/recorder/getVideoInfo'
import FixPermission from 'shared/components/FixPermission'
import PermissionsHandler from 'shared/components/PermissionsHandler'
import PACKAGE from '../../package.json'
import trim from 'lodash/trim'
import map from 'lodash/map'
import compact from 'lodash/compact'
import jsLogger from 'js-logger'
import { getBackClient } from 'controllers/back'

const SCREENSHOTS_AMOUNT = 3
const SCREENSHOTS_PERIOD = 3000

type Props = {
  verificationId: string | null
  interactionId: string
  onConversationFinished: () => void
  handleChunk: (
    videoBlob: Blob,
    mimeType: string,
    role: 'user' | 'avatar'
  ) => void
  initVersion: 0 | 1
  isTest: boolean
  setDuration: (v: number) => void
  dgKey: string
  azureKey?: { token: string; region: string }
  heygenKey?: string
  steps: TwoFactorT.StepT[]
  setSteps: (steps: TwoFactorT.StepT[]) => void
  setRecapData: (data: TwoFactorT.RecapT | null) => void
  avatar: string
}

const Conversation: FC<Props> = ({
  verificationId,
  interactionId,
  onConversationFinished,
  handleChunk,
  initVersion,
  isTest,
  setDuration,
  dgKey,
  azureKey,
  heygenKey,
  steps,
  setSteps,
  setRecapData,
  avatar
}) => {
  const startTimeRef = useRef<number>(Date.now())
  const recorderRef = useRef<IRecorder>(null)
  const heyGenRef = useRef<IHeyGenConnect>(null)
  const cameraVideoRef = useRef<HTMLVideoElement>(null)
  const isOverRef = useRef(false)
  const [orientation, setOrientation] = useState<'portrait' | 'landscape' | ''>(
    ''
  )
  const [controls, setControls] = useState<ControlT>({
    camera: isTest,
    mic: isTest
  })
  const controlsRef = useRef<ControlT>({ camera: false, mic: false })
  const [thereWasAnError, setThereWasAnError] = useState<string>()
  const [permissionsError, setPermissionsError] =
    useState<PermissionErrorType | null>(null)
  const streamRef = useRef<MediaStream | null>(null)
  const [closed, setClosed] = useState<boolean>(false)
  const [permissionsChecked, setPermissionsChecked] = useState<boolean>(false)
  const controlPanelRef = useRef<IControlPanel>(null)
  const [userName, setUserName] = useState<string | null>(null)
  const [checkingPermissions, setCheckingPermissions] = useState(false)
  const [controlPanelVisible, setControlPanelVisible] = useState(
    initVersion === 1
  )
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null)
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([])
  const avatarIsTalkingRef = useRef(true)
  const userIsTalkingRef = useRef(true)
  const nextQuestionRef = useRef<TwoFactorT.QuestionT | null>(null)
  const userSpeechBufferRef = useRef('')
  const lastAppliedPhraseRef = useRef<TwoFactorT.QuestionT>()

  useEffect(() => {
    jsLogger.info('CONVERSATION START')
    // @ts-ignore
    if (window.fireTestEvent) {
      // @ts-ignore
      window.fireTestEvent('ev_conversation_started', {
        interactionId,
        version: PACKAGE.version
      })
    }
    function handleOrientationChange (event: { matches: any; media: any }) {
      const { matches } = event
      setOrientation(matches ? 'portrait' : 'landscape')
    }

    const mediaQueryPortrait = window.matchMedia('(orientation: portrait)')
    setOrientation(mediaQueryPortrait.matches ? 'portrait' : 'landscape')

    mediaQueryPortrait.addEventListener('change', handleOrientationChange)
    return () => {
      mediaQueryPortrait.removeEventListener('change', handleOrientationChange)
    }
  }, [])

  useEffect(() => {
    if (permissionsChecked) {
      startTimeRef.current = Date.now()
    }
  }, [permissionsChecked])

  useEffect(() => {
    jsLogger.log('useEffect permissionsChecked call')
    const run = async () => {
      if (permissionsChecked && !isTest) {
        jsLogger.log('useEffect permissionsChecked, start screnshots timer', {
          permissionsChecked,
          isTest
        })
      } else if (isTest) {
        setTimeout(() => {
          applyTestScreenshots(interactionId)
        }, 6000)
      }
    }
    run()
  }, [permissionsChecked])

  useEffect(() => {
    const stream = streamRef.current as MediaStream
    if (stream) {
      const audioTrack = stream
        .getTracks()
        .find(track => track.kind === 'audio')
      if (audioTrack) {
        audioTrack.enabled = controls.mic
      }
      const videoTrack = stream
        .getTracks()
        .find(track => track.kind === 'video')
      if (videoTrack) {
        videoTrack.enabled = controls.camera
      }
    }
  }, [controls])

  const controlPanelSteps = useMemo(() => {
    return compact(
      map(steps, s => {
        if (
          s.sType === TwoFactorT.StepTypeT.QUESTION ||
          s.sType === TwoFactorT.StepTypeT.REPLY
        ) {
          return {
            id: s.id,
            text: s.text,
            isAvatar: s.sType === TwoFactorT.StepTypeT.QUESTION
          } as Step
        } else {
          return null
        }
      })
    )
  }, [steps])

  const onHeyGenSessionStarted = () => {
    jsLogger.debug('HeyGenSessionStarted')
    jsLogger.log('camera and mic are enabled, saying initial phrase!!!!!!!')
    const heygenLatency = Date.now() - startTimeRef.current
    // @ts-ignore
    if (window.fireTestEvent) {
      // @ts-ignore
      window.fireTestEvent('ev_heygen_started', {
        latency: heygenLatency,
        interactionId,
        version: PACKAGE.version
      })
    }

    const q = get(steps, 0)
    if (q.sType === TwoFactorT.StepTypeT.QUESTION) {
      lastAppliedPhraseRef.current = q
      setTimeout(() => heyGenRef.current?.say(q.text), 2000)
    }
  }

  const onCustomAvatarSessionStarted = () => {
    jsLogger.debug('CustomAvatarSessionStarted')
    jsLogger.log('Avatar is ready, saying initial phrase!!!!!!!')
    const avatarLatency = Date.now() - startTimeRef.current

    // Custom event logging
    if (window.fireTestEvent) {
      window.fireTestEvent('ev_custom_avatar_started', {
        latency: avatarLatency,
        interactionId,
        version: PACKAGE.version
      })
    }

    const q = get(steps, 0)
    if (q.sType === TwoFactorT.StepTypeT.QUESTION) {
      lastAppliedPhraseRef.current = q
      setTimeout(() => heyGenRef.current?.say(q.text), 2000)
    }
  }

  const onAvatarStartTalking = (latency: number) => {
    if (lastAppliedPhraseRef.current) {
      sendAvatarLatency(interactionId, lastAppliedPhraseRef.current.id, latency)
    }
  }

  const checkMediaPermissions = async () => {
    if (isTest) {
      setPermissionsError(null)
      setPermissionsChecked(true)
      setControlPanelVisible(true)
      return
    }
    try {
      jsLogger.debug('permissions_requested')
      setCheckingPermissions(true)
      await requestMediaPermissions()
      jsLogger.debug('permissions_granted')
      setCheckingPermissions(false)
      jsLogger.debug('no error with permissions')
      setPermissionsError(null)
      setPermissionsChecked(true)
      setControlPanelVisible(true)
      heyGenRef.current?.play()
      controlsRef.current = { mic: true, camera: true }
      setControls({ mic: true, camera: true })
    } catch (error: any) {
      jsLogger.error('MediaOnboardingDialog: ', error)
      if (error.type === MediaPermissionsErrorType.Generic) {
        setPermissionsError(PermissionErrorType.systemDenied)
      } else if (
        error.type === MediaPermissionsErrorType.SystemPermissionDenied
      ) {
        // user denied permission
        setPermissionsError(PermissionErrorType.systemDenied)
      } else if (
        error.type === MediaPermissionsErrorType.UserPermissionDenied
      ) {
        // browser doesn't have access to devices
        setPermissionsError(PermissionErrorType.userDenied)
      } else if (
        error.type === MediaPermissionsErrorType.CouldNotStartVideoSource
      ) {
        // most likely when other apps or tabs are using the cam/mic (mostly windows)
        setPermissionsError(PermissionErrorType.trackError)
      }
    }
  }

  const onAvatarPlayingFinished = () => {
    jsLogger.log('%conAvatarPlayingFinished', { isOver: isOverRef.current })
    avatarIsTalkingRef.current = false
    // @ts-ignore
    if (window.fireTestEvent) {
      // @ts-ignore
      window.fireTestEvent('ev_avatar_ended_talking', {
        isOver: isOverRef.current,
        interactionId,
        version: PACKAGE.version
      })
    }

    if (nextQuestionRef.current) {
      const q = nextQuestionRef.current
      jsLogger.log('found saved response', { q })
      sendApplyResponse(q)
      nextQuestionRef.current = null
      heyGenRef.current?.say(q.text)
      avatarIsTalkingRef.current = true
      // setIsRecording(true)
      // recorderRef.current?.start()
    } else if (isOverRef.current) {
      recorderRef.current?.stop()
      setTimeout(onConversationFinished, azureKey ? 2000 : 0)
    } else if (!userIsTalkingRef.current) {
      console.debug('avatar ended speaking and the user is not speaking')
      onUserPhrase('', 0)
    }
  }

  const sendApplyResponse = async (q: TwoFactorT.QuestionT) => {
    console.log('sendApplyResponse start', { q })
    const r = await applyResponse(interactionId, q)
    lastAppliedPhraseRef.current = q
    console.log('sendApplyResponse end', { q, steps: r && r.steps })
    if (r && r.steps) {
      setSteps(r.steps)
    }
  }

  const onUserPhrase = async (speech: string, dgLatency: number) => {
    userIsTalkingRef.current = false
    jsLogger.debug('onUserPhrase speech', speech)
    jsLogger.debug('onUserPhrase', {
      speech,
      avatarIsTalking: avatarIsTalkingRef.current
    })

    // @ts-ignore
    if (window.fireTestEvent) {
      // @ts-ignore
      window.fireTestEvent('ev_on_phrase', {
        latency: dgLatency
      })
    }
    if (avatarIsTalkingRef.current) {
      jsLogger.debug(
        'avatar is currently talking, saving user speech to buffer'
      )
      userSpeechBufferRef.current = userSpeechBufferRef.current + speech
    } else {
      jsLogger.log('onUserPhrase', { buffer: userSpeechBufferRef.current })
      const speechWithBuffer = `${userSpeechBufferRef.current} ${speech}`
      jsLogger.log('speechWithBuffer:', { speech: speechWithBuffer })
      try {
        if (trim(speechWithBuffer) === '') {
          jsLogger.debug(
            'ignore the user phrase because speechWithBuffer is empty',
            {
              speechWithBuffer,
              interactionId
            }
          )
          return null
        }
        userSpeechBufferRef.current = ''
        const res = await submitReply2(interactionId, speechWithBuffer)
        jsLogger.log('submit reply res', res)
        const q = get(res, 'q') as TwoFactorT.QuestionT
        jsLogger.debug('next question is', { text: q.text })
        const over = get(res, 'isOver', false)
        const recapData = get(
          res,
          'interaction',
          null
        ) as TwoFactorT.RecapT | null
        setRecapData(recapData)
        jsLogger.log('res is over', over)
        if (res && q) {
          // setSteps([
          //   ...stepsRef.current,
          //   generateStep(res.phrase, InteractionT.StepTypeT.QUESTION)
          // ])
          // currentQuestionId.current = res.questionId
          res.steps && setSteps(res.steps)
          if (isOverRef.current) {
            jsLogger.debug(
              'skipping the avatar reply, because the conversation is already over'
            )
          } else if (avatarIsTalkingRef.current) {
            jsLogger.debug('avatar is currently talking')
            // if (over) {
            if (!isOverRef.current && over) {
              jsLogger.debug(
                'isOver flag received, saving the avatar phrase to the buffer'
              )
              nextQuestionRef.current = q
            }
          } else if (userIsTalkingRef.current) {
            jsLogger.debug(
              'user is currently talking, skiping the avatar phrase and wait for a new one'
            )
          } else if (trim(q.text) === '') {
            jsLogger.warn('WARNING: backend returned an empty phrase')
          } else if (trim(q.text) !== '') {
            jsLogger.debug('avatar is NOT talking, switching to talking')
            sendApplyResponse(q)
            nextQuestionRef.current = null
            heyGenRef.current?.say(q.text)
            avatarIsTalkingRef.current = true
          }

          if (res.userName && isNil(userName)) {
            setUserName(res.userName)
          }
          if (over) {
            isOverRef.current = over
          }
        }
      } catch (e) {
        jsLogger.error(e)
      }
    }
  }

  const handleControlsChange = (v: ControlT) => {
    jsLogger.debug('handleControlsChange', v)
    if (!permissionsChecked) {
      checkMediaPermissions()
    }
    controlsRef.current = v
    setControls(v)
  }

  const onScreenshot = async (
    b: Blob,
    isFirst: boolean,
    isLast: boolean,
    mediaType: string
  ) => {
    if (interactionId && !isTest) {
      jsLogger.log(`start sending screenshot, size: ${b.size}`)
      const sent = await sendUserScreenshot(
        interactionId,
        b,
        isFirst,
        isLast,
        mediaType
      )
      jsLogger.log(`end sending screenshot, size: ${b.size}, isSent: ${sent}`)
      if (!sent) {
        jsLogger.warn('the screenshot was not sent')
      }
    }
  }

  const takeScreenshot = async (amount: number) => {
    jsLogger.log('takeScreenshot call start', {
      cameraInitialized: !isNil(cameraVideoRef.current?.srcObject),
      amount
    })
    if (amount <= 0) {
      jsLogger.log(
        'takeScreenshot:all screenshots were taken, exiting the loop'
      )
      return
    } else if (!cameraVideoRef.current?.srcObject) {
      jsLogger.log('takeScreenshot: no video element, exiting the loop')
      return
    } else {
      jsLogger.log('camera video ref and onScreenshot function exist')
      try {
        const isFirst = amount === SCREENSHOTS_AMOUNT
        const mediaType = 'webp'
        const quality = 0.8
        const maxSize = isFirst ? 512 : 720
        const screenshotBlob = await captureThumb(
          cameraVideoRef.current,
          mediaType,
          quality,
          maxSize
        )
        if (screenshotBlob) {
          jsLogger.log('screenshot blob created')
          const isLast = amount === 1
          await onScreenshot(screenshotBlob, isFirst, isLast, mediaType)
          if (!isLast) {
            setTimeout(() => takeScreenshot(amount - 1), SCREENSHOTS_PERIOD)
          }
        } else {
          jsLogger.error('screenshot_capture_error')
          setTimeout(() => takeScreenshot(amount), SCREENSHOTS_PERIOD)
        }
      } catch (e) {
        jsLogger.error('capturing screnshot error', { e })
        setTimeout(() => takeScreenshot(amount), SCREENSHOTS_PERIOD)
      }
    }
  }

  const onSpeech = (v: string) => {
    jsLogger.log('Conversation onSpeech', {
      speech: v,
      buffer: userSpeechBufferRef.current,
      avatarIsTalking: avatarIsTalkingRef.current,
      isOver: isOverRef.current
    })
    // FIXME: pass current transcript to controlPanel
    controlPanelRef.current?.setSpeech(`${userSpeechBufferRef.current} ${v}`)
    if (avatarIsTalkingRef.current && !isOverRef.current) {
      heyGenRef.current?.cancel()
    }
  }

  const onCameraOn = () => {
    jsLogger.log(
      `onCameraOn, taking screenshots start in: ${SCREENSHOTS_PERIOD}`
    )
    setTimeout(() => takeScreenshot(SCREENSHOTS_AMOUNT), SCREENSHOTS_PERIOD)
  }

  const handleClose = () => {
    setClosed(true)
    const back = getBackClient()
    back.disconnect()
  }

  const handleVideoClick = () => {
    if (orientation === 'portrait' && !permissionsError && permissionsChecked) {
      controlPanelRef?.current?.click()
    }
  }

  const handleCameraChange = (v: string | null) => {
    setSelectedDeviceId(v)
    recorderRef.current?.cameraChange(v)
  }

  if (closed) {
    return <EndConversation />
  }

  if (verificationId && permissionsError) {
    return (
      <PermissionsHandler
        permissionsError={permissionsError}
        // verificationId={verificationId}
      />
    )
  }

  const avatarEnabled = true

  const renderAvatar = () => {
    if (avatar === 'custom') {
      return (
        <CustomAvatarConnect
          ref={heyGenRef}
          onAvatarPlayingFinished={onAvatarPlayingFinished}
          thereWasAnError={thereWasAnError}
          isRecording={false}
          handleChunk={isTest ? undefined : handleChunk}
          handleVideoClick={handleVideoClick}
          onSessionStarted={onCustomAvatarSessionStarted}
          permissionsGranted={permissionsChecked}
        />
      )
    }
    if (azureKey) {
      return (
        <AzureConnect
          ref={heyGenRef}
          onAvatarPlayingFinished={onAvatarPlayingFinished}
          thereWasAnError={thereWasAnError}
          setThereWasAnError={setThereWasAnError}
          onSessionStarted={onHeyGenSessionStarted}
          isRecording={false}
          permissionsGranted={permissionsChecked}
          handleChunk={isTest ? undefined : handleChunk}
          setDuration={setDuration}
          handleVideoClick={handleVideoClick}
          azureKey={azureKey}
        />
      )
    } else if (heygenKey) {
      return (
        <HeyGenConnect
          ref={heyGenRef}
          onAvatarPlayingFinished={onAvatarPlayingFinished}
          thereWasAnError={thereWasAnError}
          setThereWasAnError={setThereWasAnError}
          onSessionStarted={onHeyGenSessionStarted}
          isRecording={false}
          permissionsGranted={permissionsChecked}
          handleChunk={isTest ? undefined : handleChunk}
          setDuration={setDuration}
          handleVideoClick={handleVideoClick}
          heygenKey={heygenKey}
          onAvatarStartTalking={onAvatarStartTalking}
        />
      )
    }
  }

  return (
    <PageContainer version={PACKAGE.version}>
      {avatarEnabled && renderAvatar()}
      {isTest && (
        <RecorderDummy
          ref={recorderRef}
          onPhrase={onUserPhrase}
          isActive={false}
          onSpeech={onSpeech}
          cameraVideoRef={cameraVideoRef}
          controls={controls}
          setControls={handleControlsChange}
          setThereWasAnError={setThereWasAnError}
          permissionsError={permissionsError}
          streamRef={streamRef}
        />
      )}
      {controlPanelVisible && !isTest && (
        <div
          className={`flex-1 bg-slate-100 rounded-md absolute top-[3rem] left-5 w-28 h-44`}
        >
          <Recorder
            ref={recorderRef}
            handleChunk={isTest ? undefined : handleChunk}
            onPhrase={onUserPhrase}
            isActive={true}
            onSpeech={onSpeech}
            cameraVideoRef={cameraVideoRef}
            controls={controls}
            setControls={handleControlsChange}
            setThereWasAnError={setThereWasAnError}
            permissionsError={permissionsError}
            streamRef={streamRef}
            interactionId={interactionId}
            dgKey={dgKey}
            setDevices={setDevices}
            setSelectedDeviceId={setSelectedDeviceId}
            onStartTalking={() => (userIsTalkingRef.current = true)}
            onCameraOn={onCameraOn}
          />
        </div>
      )}

      {permissionsError === PermissionErrorType.userDenied && <FixPermission />}
      {controlPanelVisible && (
        <ControlPanel
          ref={controlPanelRef}
          permissionsError={permissionsError}
          controls={controls}
          setControls={handleControlsChange}
          handleClose={handleClose}
          permissionsChecked={permissionsChecked}
          steps={controlPanelSteps}
          userName={userName}
          isRecording={true}
          orientation={orientation}
          devices={devices}
          selectedDeviceId={selectedDeviceId}
          setSelectedDeviceId={handleCameraChange}
        />
      )}
      {!controlPanelVisible && initVersion === 0 && !permissionsChecked && (
        <div className='absolute top-0 left-0 bottom-0 right-0 w-full h-full flex items-center justify-center'>
          <div className='z-10 absolute top-20 left-4 text-gray-300'>
            <div>
              <div style={{ height: '50px', width: '50px' }}>
                <DotLottieReact
                  src='https://lottie.host/1ba91606-15d0-4f27-9f17-095c58dfb5ac/IivUjwcaOY.json'
                  autoplay
                  loop
                  backgroundColor='transparent'
                  speed={1}
                  style={{ width: '100%', height: '100%' }}
                  mode='forward'
                />
              </div>
            </div>
            <span className='text-[10px] pt-1'>
              <i>Turn your volume up</i>
            </span>
          </div>
          {checkingPermissions && (
            <div className='z-10 text-[24px] text-white font-semibold flex flex-col items-center'>
              <span className='drop-shadow-[1px_1px_1px_rgba(0,0,0,1)]'>
                <i>Initializing</i>
              </span>
              <div className='h-1 w-[187px] bg-white mt-[23px]'>
                <div className='h-1 w-[106px] bg-teal-600' />
              </div>
            </div>
          )}

          {!checkingPermissions && (
            <div className='z-10 w-[300px] flex flex-col items-center'>
              <span className='font-semibold text-[24px] text-white drop-shadow-[1px_1px_1px_rgba(0,0,0,1)] text-center leading-8'>
                You're about to chat
                <br />
                with a digital assistant.
              </span>
              <div className='w-full bg-white mt-10 rounded-xl flex flex-col items-center pt-7 pb-4'>
                <span className='text-[20px] font-semibold text-center leading-7'>
                  Allow FaceSign to use your
                  <br />
                  camera and microphone?
                </span>
                <button
                  className='h-16 bg-blue-400 text-white font-medium text-[16px] w-[260px] mt-7 rounded-[4px]'
                  onClick={() =>
                    handleControlsChange({ mic: true, camera: true })
                  }
                >
                  Allow
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </PageContainer>
  )
}

export default Conversation
