import {
  useRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useEffect,
  useState
} from 'react'
import jsLogger from 'js-logger'
import isNil from 'lodash/isNil'

type Props = {
  onAvatarPlayingFinished: (latency: number) => void
  thereWasAnError?: string
  isRecording: boolean
  handleChunk?: (videoBlob: Blob, mimeType: string, role: 'avatar') => void
  handleVideoClick: () => void
  onSessionStarted: () => void
  permissionsGranted: boolean
}

const CustomAvatarConnect: ForwardRefRenderFunction<any, Props> = (
  {
    onAvatarPlayingFinished,
    thereWasAnError,
    isRecording,
    handleVideoClick,
    handleChunk,
    onSessionStarted,
    permissionsGranted
  },
  ref
) => {
  const imgRef = useRef<HTMLImageElement>(null)
  const audioRef = useRef<HTMLAudioElement>(null)
  const mediaRecorderRef = useRef<MediaRecorder | null>(null)
  const [initQuestionAsked, setInitQuestionAsked] = useState<boolean>(false)

  useEffect(() => {
    const setupMediaRecorder = async () => {
      try {
        if (imgRef.current) {
          const mediaStream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true
          })

          const mediaRecorder = new MediaRecorder(mediaStream, {
            mimeType: 'video/webm',
          })

          if (mediaRecorder) {
            onSessionStarted()
          }

          mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0 && handleChunk) {
              handleChunk(event.data, mediaRecorder.mimeType, 'avatar')
            }
          }

          mediaRecorder.start(1000)
          mediaRecorderRef.current = mediaRecorder
        }
      } catch (error) {
        jsLogger.error('Error setting up media recorder', error)
      }
    }
    const run = async () => {
      setInitQuestionAsked(true)
      await setupMediaRecorder()
    }
    if (permissionsGranted && !mediaRecorderRef.current && !initQuestionAsked) {
      run()
    }
  }, [handleChunk, onSessionStarted, permissionsGranted, initQuestionAsked])

  useImperativeHandle(ref, () => ({
    say: (text: string) => {
      startTalk(text)
    },
    cancel: () => null,
    stop: async () => {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop()
      }
    },
    play: () => null,
  }))

  const avatarCompleted = () => {
    jsLogger.log('avatar finished talking')
    onAvatarPlayingFinished(0)
    if (!isNil(imgRef.current)) {
      imgRef.current.src = 'custom_avatar_idle.gif'
    }
  }

  const startTalk = async (msg: string) => {
    jsLogger.debug('startTalk', { msg })

    try {
      const sResultRaw = await fetch(
        `https://avatar.facefile.co/findanswer?val=${msg}`
      )
      const sResult = await sResultRaw.json()
      console.log('sResult', sResult)

      const audioElement = audioRef.current
      if (audioElement) {
        audioElement.src = sResult.audio_path
        audioElement.onended = () => {
          setTimeout(() => {
            avatarCompleted()
          }, 1500)
        }
        if (imgRef.current) {
          imgRef.current.src = `https://avatar.facefile.co/videostream?t=${Date.now()}`
        }
        audioElement.play().catch((e) => console.log('Error playing audio', e))
      }
    } catch (e) {
      jsLogger.warn('Error in startTalk', e)
    }
  }

  return (
    <div
      className='h-full w-full flex flex-col justify-between flex-1 max-w-2xl relative overflow-hidden'
      onClick={handleVideoClick}
    >
      <audio ref={audioRef} autoPlay />

      <div className='w-full h-full absolute left-0 top-0 right-0 bottom-0 bg-white flex'>
        <img
          alt='avatar'
          ref={imgRef}
          src='custom_avatar_idle.gif'
          className='w-full h-full object-cover flex self-end'
        />
      </div>
      {isRecording && (
        <div className='absolute top-0 left-0 bottom-0 right-0 bg-black/60 w-full h-full' />
      )}
      {thereWasAnError && (
        <div className='absolute bottom-0 left-0 bg-white'>
          <p className='text-red'>{thereWasAnError}</p>
        </div>
      )}
    </div>
  )
}

export default forwardRef(CustomAvatarConnect)
