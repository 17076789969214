import { FaceDetection, FaceDetail } from 'types/amazon'
import { DeviceDetailsT } from 'types/deviceDetails'
import { IpDetailsT, MaxmindCityT } from 'types/ipDetails'
export namespace TwoFactorT {
  export enum StepTypeT {
    QUESTION = 'question',
    LANDING = 'landing',
    REPLY = 'reply'
  }

  export type BrandingT = {
    url: string
    fit: 'cover' | 'logo'
  }

  export type ButtonT = {
    label: string
    variant?: 'solid' | 'outline' | null
    color?: string | null
  }

  export type LandingT = {
    id: 'landing'
    branding?: BrandingT
    primaryHeading: string
    locationSubheading?: string
    ctaText: string
    termsAndConditionsUrl?: string
    actionButton: ButtonT
    actionButton2: ButtonT
    buttonPressed?: 0 | 1
    sType: StepTypeT.LANDING
  }

  export interface QuestionT {
    id: string
    createdAt: number
    length?: number
    thumbnail?: string
    mediaUrl?: string
    text: string
    answerText?: string
    answerId?: string
    sType: StepTypeT.QUESTION
    aiLatency?: number
    heyGenLatency?: number
    isApplied?: boolean
  }

  export interface ReplyT {
    id: string
    text: string
    createdAt: number
    mediaUrl?: string
    sType: StepTypeT.REPLY
    name?: string
    deepgramLatency?: number
  }

  export type StepT = QuestionT | LandingT | ReplyT

  export type RekognitionT = {
    startTime: number
    endTime: number
    faces: FaceDetection[]
  }

  export type VariantT = 'LlmSystem' | 'OpenAI' | 'Custom'
  export type AvatarT = 'heygen' | 'azure' | 'custom'

  export type ImageRekognitionT = {
    startTime: number
    endTime: number
    faces: FaceDetail[]
  }

  export type AIAnalysisSection = {
    title: string
    shortDescription: string
    longDescription: string
  }

  export type AIAnalysis = {
    bestPhotoIndex: number
    ageMin: number
    ageMax: number
    sex: 'male' | 'female'
    realPersonOrVirtual: 'real' | 'virtual'
    overallSummary: string
    analysis: AIAnalysisSection[]
  }

  export type EmailAttemptT = {
    ts: string
    timestamp: number
    deliveredAt?: number
  }

  export type VerificationT = {
    id: string
    code: string
    email: string
    createdAt: number
    agreed: boolean
    slackNotifications?: Record<string, EmailAttemptT>
    isTest?: boolean
  }

  export type T = {
    id: string
    createdAt: number
    steps: StepT[]
    completed: boolean
    rekognition?: Record<string, RekognitionT>
    deviceDetails?: DeviceDetailsT
    ipDetails?: IpDetailsT
    userId?: string
    isNotNew?: boolean
    slackTs?: string
    slackNotificationsDisabled?: boolean
    variant?: VariantT
    transcriptSlackTs?: string
    logsSlackTs?: string
    avatar?: AvatarT
    verificationId: string
    isVerified?: boolean
    userName?: string
    userVideoUrl?: string
    avatarVideoUrl?: string
    userVideoMimeType?: string
    avatarVideoMimeType?: string
    imageRekognition?: ImageRekognitionT[]
    userAvatarUrl?: string
    userScreenshots?: string[]
    chunksCompleted?: boolean
    webmDuration?: number
    isTest?: boolean
    version?: string
    model?: string
    isDev?: boolean
    firstName: null | string
    lastName: null | string
    recapVideoUrl?: string
    aiAnalysisFirst?: AIAnalysis
    aiAnalysis?: AIAnalysis
    ip?: string
    location?: MaxmindCityT
  }

  export type RecapT = T
}
