import jsLogger from 'js-logger'

export const captureThumb = (
  videoTag: HTMLVideoElement,
  mediaType: 'jpeg' | 'png' | 'webp' = 'png',
  quality = 1,
  maxSize = 480
): Promise<Blob | null> =>
  new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas')

    const maxDimension = Math.max(videoTag.videoWidth, videoTag.videoHeight)
    const scale = maxDimension > maxSize ? maxSize / maxDimension : 1
    const w = Math.ceil(videoTag.videoWidth * scale)
    const h = Math.ceil(videoTag.videoHeight * scale)
    canvas.width = w
    canvas.height = h
    jsLogger.log('captureThumb: canvas created', {
      videoWidth: videoTag.videoWidth,
      videoHeight: videoTag.videoHeight,
      scaledWidth: w,
      scaledHeight: h
    })
    const context = canvas.getContext('2d')

    if (context) {
      jsLogger.log('captureThumb: getContext: done')
      context.drawImage(
        videoTag,
        0, // top
        0, // left
        videoTag.videoWidth,
        videoTag.videoHeight,
        0, // destination top
        0, // destination left
        w, // destination width
        h // destination height
      )
      jsLogger.log('captureThumb: image is drawn and scaled')
      canvas.toBlob(
        thumbnail => {
          jsLogger.log('captureThumb, blob callback received')
          resolve(thumbnail)
        },
        'image/' + mediaType,
        quality
      ) // Use JPEG format with 95% quality for better compression
    } else {
      jsLogger.error('could not get context')
      reject('context has not been created')
    }
  })

const getVideoInfo = (
  videoBlob: Blob
): Promise<{ duration: number | null; thumbnail: Blob | null }> =>
  new Promise((resolve, reject) => {
    const videoTag = document.createElement('video')
    videoTag.preload = 'metadata'
    videoTag.muted = true
    videoTag.defaultMuted = true
    videoTag.playsInline = true
    videoTag.autoplay = true

    let resolved = false

    const handleTimeout = () => {
      resolved = true
      resolve({
        duration: null,
        thumbnail: null
      })
      videoTag.removeEventListener &&
        videoTag.removeEventListener('loadeddata', handleLoadedData)
      window.URL.revokeObjectURL(videoTag.src)
    }

    const timeout = setTimeout(handleTimeout, 1000)

    const handleVideoTag = (duration: number) => {
      captureThumb(videoTag)
        .then(thumbnail => {
          videoTag.pause()
          if (!resolved) {
            clearTimeout(timeout)
            resolved = true
            resolve({ duration, thumbnail })
          }
          window.URL.revokeObjectURL(videoTag.src)
        })
        .catch(err => {
          if (!resolved) {
            clearTimeout(timeout)
            resolved = true
            reject(err)
          }
        })
    }

    const handleLoadedData = () => {
      let duration = videoTag.duration * 1000
      if (videoTag.duration === Infinity) {
        videoTag.currentTime = Number.MAX_SAFE_INTEGER
        videoTag.ontimeupdate = () => {
          videoTag.ontimeupdate = null
          duration = videoTag.duration * 1000
          videoTag.currentTime = 0
          handleVideoTag(duration)
        }
      } else {
        handleVideoTag(duration)
      }
    }

    videoTag.addEventListener('loadeddata', handleLoadedData)
    videoTag.src = window.URL.createObjectURL(videoBlob)
  })

export default getVideoInfo
